<template>
  <div class="travelCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>物流列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>物流名称:</span>
      <el-input v-model="list.expressName" placeholder="请输入物流名称"></el-input>
      <el-button type="primary" @click="getList()">查找</el-button>
      <el-button type="primary" @click="addDialogVisible = true" v-if="
          $store.state.powerList.indexOf('selectMall:express:list:add')!==-1
        ">添加</el-button>
    </div>
    <el-table :data="tableData" style="width:100%" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="100">
      </el-table-column>
      <el-table-column align="center" prop="expressSort" label="排序" width="width">
        <template slot-scope="{ row }">
          <el-input class="input-new-tag" v-if="row.inputVisible" v-model="row.expressSort" ref="saveTagInput" size="small"
            @input="onInputInt" @keyup.enter.native="handleInputConfirm(row)" @blur="handleInputConfirm(row)">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="onChangeSort(row)">{{ row.expressSort }}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expressId" label="物流ID" width="width"></el-table-column>
      <el-table-column align="center" prop="expressName" label="物流名称" width="width"></el-table-column>
      <el-table-column align="center" prop="expressIco" label="物流logo" width="width">
        <template slot-scope="{ row }">
          <el-image style="width:50px; height:50px" :src="row.expressIco" :preview-src-list="[row.expressIco]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="expressCode" label="物流编号" width="width"></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onEdit(row)" v-if="
              $store.state.powerList.indexOf('selectMall:express:list:edit')!==
              -1
            ">编辑</el-button>
          <el-button type="primary" size="mini" @click="onDel([row])" v-if="
              $store.state.powerList.indexOf(
                'selectMall:express:list:delete'
              )!==-1
            ">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="list.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>

    <el-button style="margin-top:20px" type="primary" @click="onDel(selection)" v-if="
        $store.state.powerList.indexOf('selectMall:express:list:delete')!==-1
      ">批量刪除</el-button>
    <el-dialog :close-on-click-modal="false" title="添加物流" :visible.sync="addDialogVisible" width="30%">
      <el-form ref="form" label-width="80px">
        <el-form-item label="物流名称">
          <el-input v-model="addForm.expressName" placeholder="请输入物流名称"></el-input>
        </el-form-item>
        <el-form-item label="物流排序">
          <el-input v-model="addForm.expressSort" placeholder="请输入物流排序"></el-input>
        </el-form-item>
        <el-form-item label="物流编号">
          <el-input v-model="addForm.expressCode" placeholder="请输入快递鸟物流对应编码"></el-input>
        </el-form-item>
        <el-form-item label="物流logo">
          <send-image v-if="addDialogVisible" @addImageStr="(e) => addImageStr(e, 1)"></send-image>
        </el-form-item>
        <div style="color:red; margin-left:80px">建议尺寸168*168px</div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑物流弹层 -->
    <el-dialog :close-on-click-modal="false" title="编辑物流" :visible.sync="editDialogVisible">
      <el-form ref="form" label-width="80px">
        <el-form-item label="物流名称">
          <el-input v-model="editForm.expressName"></el-input>
        </el-form-item>
        <el-form-item label="物流排序">
          <el-input v-model="editForm.expressSort" placeholder="请输入物流排序"></el-input>
        </el-form-item>
        <el-form-item label="物流编号">
          <el-input v-model="editForm.expressCode" placeholder="请输入快递鸟物流对应编码"></el-input>
        </el-form-item>
        <el-form-item label="物流logo">
          <send-image :images="editForm.expressIco" v-if="editDialogVisible" @addImageStr="(e) => addImageStr(e, 2)"></send-image>
        </el-form-item>
        <div style="color:red; margin-left:80px">建议尺寸168*168px</div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    sendFile
  } from "../../api/sendFile";
  import {
    expressList,
    expressEdit,
    expressDelete
  } from "../../api/selectMall";
  import sendImage from "../../components/sendImage.vue";
  export default {
    components: {
      sendImage
    },
    name: "selectMallExpress",
    data() {
      return {
        addDialogVisible: false,
        editDialogVisible: false,
        addForm: {
          expressName: "",
          expressSort: "",
          expressIco: "",
          expressCode:'',
        },
        editForm: {
          expressName: "",
          expressSort: "",
          expressIco: "",
          expressCode:'',
        },
        list: {
          currentPage: 1,
          pageSize: 5,
          expressName: "",
        },
        tableData: [],
        selection: [],
        pagination: {},
      };
    },
    created() {
      this.changeId();
    },
    mounted() {},
    methods: {
      toNext(row) {
        this.tableData = [];
        this.$router.push("/selectMall/typeList?id=" + row.id);
      },
      changeId() {
        console.log("挂载 $route:", this.$route);
        this.getList();
      },
      async getList() {
        const {
          data
        } = await expressList(this.list);
        data.list.map((item) => {
          item.inputVisible = false;
        });
        this.tableData = data.list;
        this.pagination = data.pagination;
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      async onAddOk() {
        if (this.addForm.expressName === "" 
        || this.addForm.expressSort === ""
        || this.addForm.expressIco === "") {
          this.$message({
            message: "请把数据填写完整",
            type: "warning",
          });
          return;
        }
        const {
          data
        } = await expressEdit(this.addForm);
        if (data.code == 0) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.addForm.expressName = "";
        }
        this.addDialogVisible = false;
        this.getList();
      },
      onDel(arr) {
        if (!arr || arr.length < 1) {
          this.$message({
            message: "请选择删除项!",
            type: "error"
          });
          return;
        }
        let ids = arr
          .map((item) => {
            return item.expressId;
          })
          .join(",");

        this.$confirm("是否删除这条物流?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async () => {
            const {
              data
            } = await expressDelete({
              ids: ids,
            });
            console.log(data);
            if (data.code == 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      onInputInt(value) {
        var reg = /^[0-9]*[1-9][0-9]*$/;
        var reg2 = /^-[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value) && !reg2.test(value)) {
          return this.$message({
            type: "warning",
            message: "请输入整数!",
          });
        }
      },
      async onEdit(row) {
        console.log(row,'row')
        this.editForm.expressId = row.expressId;
        this.editForm.expressSort = row.expressSort;
        this.editForm.expressName = row.expressName;
        this.editForm.expressIco = row.expressIco;
        this.editForm.expressCode = row.expressCode;
        this.editDialogVisible = true;
      },
      async onEditOk() {
        const {
          data
        } = await expressEdit(this.editForm);
        console.log(data);
        if (data.code == 0) {
          this.editDialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "编辑成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "编辑失败",
          });
        }
      },
      async handleInputConfirm(row) {
        const {
          data
        } = await expressEdit({
          expressId: row.expressId,
          expressName: row.expressName,
          expressSort: Number(row.expressSort),
        });
        if (data.code !== 0) {
          this.$message({
            message: "设置失败",
            type: "error",
          });
        } else {
          this.$message({
            message: "设置成功",
            type: "success",
          });
        }
        row.inputVisible = false;
      },
      addImageStr(image, type) { // 源代码
        type == 1 ? this.addForm.expressIco = image : type == 2 ? this.editForm.expressIco = image : ''
      },
      handleSelectionChange(val) {
        this.selection = val;
      },
      handleSizeChange(num) {
        this.list.pageSize = num;
        this.getList();
      },
      handleCurrentChange(num) {
        this.list.currentPage = num;
        this.getList();
      },
    },
  };
</script>
<style lang="less" scoped>
  .travelCateList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }

    .search {
      margin-top: 50px;

      .el-input {
        width: 200px;
        margin: 0 40px 0 20px;
      }
    }

    .el-table {
      margin-top: 30px;

      .el-table__body-wrapper {
        table {
          td {
            .cell {
              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }

    /deep/.el-dialog {
      .el-form-item__content {
        display: flex;

        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 20px 20px;

          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }

          div {
            position: absolute;
            top: 0;
            right: 2px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }

        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 20px;

          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
          }

          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }

    .el-pagination {
      margin-top: 30px;
      float: right;
    }
  }
</style>
